var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main" }, [
    _c(
      "div",
      {
        staticStyle: {
          "border-left": "2px solid black",
          "border-bottom": "2px solid black",
        },
      },
      [
        _c(
          "el-row",
          [
            _c(
              "el-col",
              { staticClass: "main-header", attrs: { span: 24 } },
              [
                _c("el-col", { attrs: { span: 12, offset: 0 } }, [
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "margin-left": "10px",
                        "font-weight": "bold",
                        "font-size": "20px",
                      },
                    },
                    [_vm._v(_vm._s(_vm._taskName))]
                  ),
                ]),
                _c(
                  "el-col",
                  {
                    staticStyle: {
                      display: "flex",
                      "justify-content": "flex-end",
                    },
                    attrs: { span: 12 },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "margin-right": "20px",
                          "font-weight": "bold",
                          cursor: "pointer",
                        },
                        on: { click: _vm.goToHome },
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "icon",
                            attrs: {
                              t: "1643079214398",
                              viewBox: "0 0 1024 1024",
                              version: "1.1",
                              xmlns: "http://www.w3.org/2000/svg",
                              "p-id": "2048",
                              width: "16",
                              height: "16",
                            },
                          },
                          [
                            _c("path", {
                              attrs: {
                                d: "M588.468659 257.265591H123.316451L371.227243 58.55359a31.947267 31.947267 0 1 0-39.614611-49.837737l-319.472671 255.578137v11.501016a30.669376 30.669376 0 0 0 0 4.472617v3.194727a30.669376 30.669376 0 0 0 0 4.472617v11.501016l319.472671 255.578137a31.947267 31.947267 0 1 0 40.253556-49.837737L123.316451 321.160125h465.152208C792.292223 321.160125 958.418011 464.283881 958.418011 640.632795s-166.125789 319.47267-369.949352 319.472671H95.841801a31.947267 31.947267 0 0 0 0 63.894534h492.626858C830.628943 1024 1022.312545 852.123703 1022.312545 640.632795s-191.683602-383.367205-433.843886-383.367204z",
                                fill: "#ffffff",
                                "p-id": "2049",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" 返回首页 "),
                      ]
                    ),
                    _vm.currTabName === "form"
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              "margin-right": "20px",
                              "font-weight": "bold",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.changeTab("flow")
                              },
                            },
                          },
                          [_vm._v(" 切换流程图>> ")]
                        )
                      : _vm._e(),
                    _vm.currTabName === "flow"
                      ? _c(
                          "span",
                          {
                            staticStyle: {
                              "margin-right": "20px",
                              "font-weight": "bold",
                              cursor: "pointer",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.changeTab("form")
                              },
                            },
                          },
                          [_vm._v(" 切换表单>> ")]
                        )
                      : _vm._e(),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "main-content" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 10 } },
          [
            _c("el-col", { attrs: { span: 19, offset: 0 } }, [
              _c("div", { staticClass: "main-card" }, [
                _c(
                  "div",
                  {
                    staticClass: "deal-content",
                    style: { color: _vm.fontColor ? _vm.fontColor : "black" },
                  },
                  [
                    _c("form-viewer", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currTabName == "form",
                          expression: "currTabName == 'form'",
                        },
                      ],
                      ref: "formViewerRef",
                      staticClass: "deal-form",
                      style: { borderColor: _vm.borderColor },
                      attrs: { btnObj: _vm.currentLayoutBtn },
                      on: { submit: _vm.handleOperation },
                    }),
                    _c("flow-graph", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.currTabName == "flow",
                          expression: "currTabName == 'flow'",
                        },
                      ],
                      ref: "flowGrapgRef",
                      staticClass: "deal-flow",
                      attrs: { flowId: _vm.flowId, readonly: true },
                    }),
                    _c(
                      "el-footer",
                      {
                        staticClass: "deal-btns",
                        style: { borderColor: _vm.borderColor },
                      },
                      _vm._l(_vm.curOptBtns, function (item) {
                        return _c(
                          "el-button",
                          {
                            key: item.actionKey,
                            staticClass: "deal-btn-item",
                            style: {
                              backgroundColor: _vm.fontColor,
                              borderColor: _vm.borderColor,
                            },
                            attrs: { type: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.handleOperation(item)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item.displayActionName) + " ")]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]),
            _c("el-col", { attrs: { span: 5, offset: 0 } }, [
              _c("div", { staticClass: "main-card" }, [
                _c("div", { staticClass: "deal-logs" }, [
                  _c(
                    "h2",
                    {
                      staticStyle: { font: "normal bold 20px sans-serif" },
                      style: { color: _vm.fontColor },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("lang_client_flow_description")) + "："
                      ),
                    ]
                  ),
                  _c("span", {
                    style: { color: _vm.fontColor },
                    domProps: { innerHTML: _vm._s(_vm._description) },
                  }),
                ]),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }